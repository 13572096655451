import { styled } from "@mui/material";
import { useRef, FocusEvent, ComponentPropsWithRef, useCallback } from "react";
import TouchRipple, {
  TouchRippleActions,
} from "@mui/material/ButtonBase/TouchRipple";

const StyledAnchor = styled("a")(() => ({
  textDecoration: "none",
  color: "inherit",
  outline: 0,
  position: "relative",
  height: "100%",
  display: "block",
  borderRadius: "inherit",
  overflow: "hidden",
}));

interface Props extends ComponentPropsWithRef<"a"> {
  ripplePulsate?: boolean;
}

export const NeutralAnchor = ({ children, ripplePulsate, ...props }: Props) => {
  const rippleRef = useRef<TouchRippleActions | null>(null);

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLAnchorElement>) => {
      rippleRef.current?.start(undefined, { pulsate: ripplePulsate });
      props.onFocus?.(event);
    },
    [ripplePulsate, props],
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLAnchorElement>) => {
      rippleRef.current?.stop(undefined);
      props.onBlur?.(event);
    },
    [props],
  );

  return (
    <StyledAnchor {...props} onFocus={handleFocus} onBlur={handleBlur}>
      {children}
      <TouchRipple ref={rippleRef} />
    </StyledAnchor>
  );
};

import { Avatar, styled, NoSsr } from "@mui/material";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import type { Property } from "csstype";
import { handleKeyEvent } from "../../helpers/accessibility";
import { mergeArraysUnique } from "../../helpers/array";
import { ThemeChip } from "./ThemeComponents/ThemeChip";
import { Option as FilterOption, OptionValue } from "./Filter";
import { SeoAnchor } from "./anchor/SeoAnchor";
import { SeeMoreButton } from "./SeeMoreButton";
import { SSR } from "../../helpers/environment";
import { useReRenderOnResize } from "../../helpers/reRenderOnResize";

type Height = Property.Height<string | number>;

const animationTime = 250;

interface Props {
  options: FilterOption[];
  onChange: (checked: OptionValue[]) => void;
  initialSelectedOptionValues?: OptionValue[];
  maxRowsBeforeShowMore?: number;
  seoHrefPrefix: string;
  title: string;
}

const margin = 8;
const defaultHeight = 200;

const OptionContainer = styled("li")(() => ({
  display: "inline-block",
}));

const ButtonContainer = styled("div")(() => ({
  textAlign: "center",
  marginTop: margin,
}));

const ClickableChip = styled(ThemeChip)(() => ({
  cursor: "pointer",
}));

const StyledChipAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: `${theme.palette.text.chipDefault} !important`,
  lineHeight: 0,
}));

const OptionListContainer = styled("ul")<{
  currentHeight: Height;
}>(({ currentHeight }) => ({
  height: currentHeight,
  overflow: "hidden",
  gap: margin,
  display: "flex",
  flexWrap: "wrap",
  textAlign: "inherit",
  transition: `${animationTime}ms ease-in-out`,
}));
interface OptionProps {
  option: FilterOption;
  checked: boolean;
  onChange: () => void;
  tabIndex?: number;
}
const Option = ({
  option: { detailsLabel, label },
  checked,
  onChange,
  tabIndex,
}: OptionProps) => (
  <ClickableChip
    onClick={() => onChange()}
    variant={checked ? "filled" : "outlined"}
    color="primary"
    label={label}
    avatar={
      detailsLabel ? (
        <StyledChipAvatar>{detailsLabel}</StyledChipAvatar>
      ) : undefined
    }
    tabIndex={tabIndex}
  />
);

// eslint-disable-next-line react/no-multi-comp
const OptionList: FunctionComponent<Props> = ({
  options,
  onChange,
  seoHrefPrefix,
  initialSelectedOptionValues = [],
  maxRowsBeforeShowMore = 3,
  title,
}: Props) => {
  const [optionHeight, setOptionHeight] = useState(defaultHeight);
  const [containerHeight, setContainerHeight] = useState<Height | undefined>();
  const [open, setOpen] = useState(false);
  const [doneRendering, setDoneRendering] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  useReRenderOnResize();
  const rowHeight = optionHeight + margin;

  const heightByMaxRows = rowHeight * maxRowsBeforeShowMore;

  const allOptionsAreShowed =
    typeof containerHeight === "number" && heightByMaxRows > containerHeight;

  const collapsedHeight = allOptionsAreShowed
    ? containerHeight
    : heightByMaxRows;

  const showSeeMore = !hideButton && !allOptionsAreShowed;

  const currentContainerHeight = open
    ? containerHeight || "auto"
    : collapsedHeight - (showSeeMore ? margin : 0) || "auto";

  useEffect(() => {
    setDoneRendering(true);
  }, []);

  const handleChange = useCallback(
    (isChecked: boolean, value: string) => {
      const newValues = !isChecked
        ? mergeArraysUnique(initialSelectedOptionValues, [value])
        : initialSelectedOptionValues.filter(
            (currentValue) => currentValue !== value,
          );
      onChange(newValues);
      return newValues;
    },
    [initialSelectedOptionValues, onChange],
  );

  return (
    <>
      <div
        style={{
          overflow: doneRendering ? "visible" : "hidden",
          height: doneRendering ? "auto" : heightByMaxRows,
        }}
      >
        <OptionListContainer
          aria-label={title}
          role="list"
          currentHeight={
            containerHeight && doneRendering ? currentContainerHeight : "auto"
          }
          ref={(node) => {
            if (node && !SSR) {
              setContainerHeight(hideButton ? "auto" : node.scrollHeight);
            }
          }}
        >
          {options.map((option, index) => {
            const checked = initialSelectedOptionValues.includes(option.value);
            return (
              <OptionContainer
                key={`${option.value}${index}`}
                {...(!index
                  ? {
                      ref: (node) => {
                        if (!SSR) {
                          setOptionHeight(node?.offsetHeight || defaultHeight);
                        }
                      },
                    }
                  : {})}
              >
                <SeoAnchor
                  href={`${seoHrefPrefix}${option.value}`}
                  ripplePulsate
                  aria-label={`${
                    option.detailsLabel ? `(${option.detailsLabel}) ` : ""
                  }${option.label}`}
                  onKeyDown={handleKeyEvent({
                    onEnter: () => handleChange(checked, option.value),
                  })}
                >
                  <Option
                    option={option}
                    checked={checked}
                    onChange={() => handleChange(checked, option.value)}
                    tabIndex={-1}
                  />
                </SeoAnchor>
              </OptionContainer>
            );
          })}
        </OptionListContainer>
        {showSeeMore && (
          <NoSsr>
            <ButtonContainer>
              <SeeMoreButton
                onClick={() => {
                  setOpen(true);
                  setTimeout(() => setHideButton(true), animationTime);
                }}
              >
                See More
              </SeeMoreButton>
            </ButtonContainer>
          </NoSsr>
        )}
      </div>
    </>
  );
};

export default OptionList;

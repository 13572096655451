import { styled } from "@mui/material";
import { FunctionComponent } from "react";

import { Option, OptionValue } from "../Filter";
import CheckboxGroup from "./CheckboxGroup";

export interface CheckboxTreeProps {
  initialSelectedOptionValues?: OptionValue[];
  onChange: (checked: OptionValue[], prev: OptionValue[]) => void;
  defaultCollapsed?: boolean;
}

interface Props extends CheckboxTreeProps {
  options: Option[];
  seoHrefPrefix: string;
}

const StyledParentCheckboxGroupContainer = styled("div")(() => ({
  marginBottom: ".6rem",
}));

const CheckboxTree: FunctionComponent<Props> = ({
  options,
  defaultCollapsed,
  seoHrefPrefix,
  initialSelectedOptionValues = [],
  onChange,
}: Props) => (
  <>
    {options.map((option, index) => (
      <StyledParentCheckboxGroupContainer key={index}>
        <CheckboxGroup
          option={option}
          defaultCollapsed={defaultCollapsed}
          onChange={(checked) => {
            const initialValues = [...initialSelectedOptionValues];
            // get option values
            const optionValues = [option.value];
            option.subOptions?.forEach((subOption) =>
              optionValues.push(subOption.value),
            );
            const finalValues = initialValues.filter(
              (el) => !optionValues.includes(el),
            );
            onChange(
              finalValues.concat(checked).map((el) => el),
              [],
            );
          }}
          initialSelectedOptionValues={initialSelectedOptionValues}
          seoHrefPrefix={seoHrefPrefix}
        />
      </StyledParentCheckboxGroupContainer>
    ))}
  </>
);

export default CheckboxTree;

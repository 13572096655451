import { MouseEvent, MouseEventHandler, ReactNode } from "react";
import { LinkProps } from "@mui/material";
import { NeutralAnchor } from "./NeutralAnchor";

interface Props extends Pick<LinkProps, "onKeyDown"> {
  children: ReactNode;
  href: string;
  onClick?: MouseEventHandler;
  ripplePulsate?: boolean;
}
export function SeoAnchor({
  children,
  href,
  ripplePulsate,
  onClick,
  ...props
}: Props) {
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    onClick && onClick(e);
  };

  return (
    <NeutralAnchor
      href={href}
      onClick={handleClick}
      ripplePulsate={ripplePulsate}
      {...props}
    >
      {children}
    </NeutralAnchor>
  );
}

import { FunctionComponent, useMemo } from "react";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material";
import { sanitize } from "isomorphic-dompurify";

interface Props {
  showLinks?: boolean;
}

const TermsText: FunctionComponent<Props> = () => {
  const { settings } = useTheme();
  const customTerms = useMemo(
    () => sanitize(settings?.termsAndConditions, { ADD_ATTR: ["target"] }),
    [settings?.termsAndConditions],
  );

  return customTerms ? (
    <div
      dangerouslySetInnerHTML={{
        __html: customTerms,
      }}
    />
  ) : (
    <>
      By continuing you accept our{" "}
      <Link
        href="https://www.builtfirst.com/terms-and-condiitons"
        target="_blank"
      >
        Terms and Conditions
      </Link>{" "}
      and{" "}
      <Link href="https://www.builtfirst.com/privacy-policy" target="_blank">
        Privacy Policy
      </Link>
    </>
  );
};

export default TermsText;

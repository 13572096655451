import { IconButton, styled, IconButtonProps } from "@mui/material";

export const StyledCloseButton = styled(IconButton)(({ theme }) => {
  const spacing = 20;
  return {
    position: "absolute",
    zIndex: theme.zIndex.modal,
    right: spacing,
    top: spacing,
    color: theme.palette.text.paper,
    width: "1rem",
    height: "1rem",
    [theme.breakpoints.up("md")]: {
      width: "2rem",
      height: "2rem",
    },
  };
});

type Props = Pick<IconButtonProps, "onClick" | "children">;

export function CloseButton(props: Props) {
  return (
    <StyledCloseButton
      aria-label="Close" // TODO: handle translation
      {...props}
    />
  );
}

/* eslint-disable react/no-multi-comp */
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  List,
  ListItemButton,
  styled,
  useTheme,
} from "@mui/material";
import {
  FunctionComponent,
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import { handleKeyEvent } from "src/helpers/accessibility";
import { mergeArraysUnique } from "../../../helpers/array";
import type { CheckboxTreeProps } from ".";
import { Option as FilterOption, OptionValue } from "../Filter";
import { SeoAnchor } from "../anchor/SeoAnchor";

interface Props extends CheckboxTreeProps {
  option: FilterOption;
  seoHrefPrefix: string;
}

const Label = styled("div")(({ theme }) => ({
  padding: ".3rem",
  minWidth: "2rem",
  textAlign: "center",
  borderRadius: ".5rem",
  fontWeight: "bold",
  fontSize: theme.typography.body2.fontSize,
  margin: ".2rem",
  background: theme.palette.inputs.default,
  border: `1px solid ${theme.palette.inputsUnderline.main}`,
  color: theme.palette.text.inputDefault,
}));

const expandContainerWidth = 2;

const SubLabel = styled(Label)(({ theme }) => ({
  marginRight: `${expandContainerWidth + 0.2}rem`,
  color: theme.palette.text.inputDefault,
}));

const ExpandIconContainer = styled("div")<{ invisible: boolean }>(
  ({ invisible }) => ({
    width: `${expandContainerWidth}rem`,
    visibility: invisible ? "hidden" : "initial",
  }),
);

const ExpandIcon = styled("div")(({ theme }) => ({
  marginLeft: ".5rem",
  color: theme.palette.text.default,
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => {
  const padding = "0.1rem";
  return {
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.defaultSpacing.md.page.edges,
    },
    paddingTop: padding,
    paddingBottom: padding,
  };
});

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  width: "100%",
  color: theme.palette.text.default,
  "& .MuiTypography-root": {
    lineHeight: 1.1,
    fontSize: theme.typography.body1.fontSize,
  },
  wordBreak: "break-word",
}));

const StyledFormControlLabelSubOption = styled(StyledFormControlLabel)(
  ({ theme }) => ({
    "& .MuiTypography-root": {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.text.default,
    },
  }),
);

const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  paddingTop: `${0} !important`,
  paddingBottom: `${0} !important`,
  color: theme.palette.text.default,
}));

const SubOptionsList = styled(List)(() => ({ paddingLeft: "1rem" }));

type OnChangeEventType = Pick<Event, "preventDefault" | "stopPropagation">;
interface OptionProps {
  haveSubOptions: boolean;
  handleChange: (e: OnChangeEventType) => void;
  handleOnChangeAll: () => void;
  expanded: boolean;
  detailsLabel?: string;
  label: string;
  value: string;
  allChecked: boolean;
  indeterminate: boolean;
  onClickLabel: MouseEventHandler<HTMLLabelElement>;
  tabIndex?: number;
}

const Option = ({
  haveSubOptions,
  handleChange,
  handleOnChangeAll,
  expanded,
  detailsLabel,
  label,
  value,
  allChecked,
  indeterminate,
  onClickLabel,
  tabIndex,
}: OptionProps) => {
  const theme = useTheme();
  const ArrowDownIcon = theme.icons.arrowDown;
  const ArrowUpIcon = styled(ArrowDownIcon)(() => ({
    transform: "rotate(180deg)",
  }));
  return (
    <StyledListItemButton onClick={handleChange} tabIndex={tabIndex}>
      <StyledFormControlLabel
        key={value}
        onClick={onClickLabel}
        control={
          <StyledCheckBox
            indeterminate={indeterminate}
            checked={allChecked}
            onChange={handleOnChangeAll}
            tabIndex={tabIndex}
          />
        }
        label={label}
        tabIndex={tabIndex}
      />
      {detailsLabel && <Label tabIndex={tabIndex}>{detailsLabel}</Label>}
      <ExpandIconContainer invisible={!haveSubOptions}>
        <ExpandIcon>
          {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </ExpandIcon>
      </ExpandIconContainer>
    </StyledListItemButton>
  );
};

const CheckboxGroup: FunctionComponent<Props> = ({
  option: { label, value, subOptions, detailsLabel },
  onChange,
  defaultCollapsed,
  seoHrefPrefix,
  initialSelectedOptionValues = [],
}: Props) => {
  const haveSubOptions = !!subOptions?.length;
  const [expanded, setExpanded] = useState(!defaultCollapsed);

  const subOptionsValues = useMemo(
    () => subOptions?.map(({ value }) => value) || [],
    [subOptions],
  );

  const checkIfAllSelected = useCallback(
    (values: OptionValue[]) =>
      haveSubOptions &&
      subOptionsValues.every((value) => values.includes(value)),
    [subOptionsValues, haveSubOptions],
  );

  const indeterminate = useMemo(
    () =>
      subOptionsValues?.some((value) =>
        initialSelectedOptionValues.includes(value),
      ) && !checkIfAllSelected(initialSelectedOptionValues),
    [initialSelectedOptionValues, subOptionsValues, checkIfAllSelected],
  );

  const getToggledOptionValues = (values: OptionValue[], value: OptionValue) =>
    values.includes(value)
      ? values.filter((currentValue) => currentValue !== value)
      : [...values, value];

  const onClickLabel: MouseEventHandler<HTMLLabelElement> = useCallback(
    (e) => {
      if (haveSubOptions) {
        e.stopPropagation();
      }
    },
    [haveSubOptions],
  );

  const handleOnChange = useCallback(
    (optionValue: OptionValue) => {
      if (haveSubOptions) {
        let newValues = getToggledOptionValues(
          initialSelectedOptionValues,
          optionValue,
        );

        newValues = checkIfAllSelected(newValues)
          ? mergeArraysUnique(newValues, [value])
          : newValues.filter((optionValue) => optionValue != value);
        onChange(newValues, []);
      } else {
        const newValues = getToggledOptionValues(
          initialSelectedOptionValues,
          value,
        );
        onChange(newValues, []);
      }
    },
    [
      onChange,
      getToggledOptionValues,
      checkIfAllSelected,
      haveSubOptions,
      value,
    ],
  );

  const allChecked =
    initialSelectedOptionValues.includes(value) ||
    checkIfAllSelected(initialSelectedOptionValues);

  const handleOnChangeAll = () => {
    if (allChecked) {
      onChange([], []);
    } else {
      onChange([value, ...subOptionsValues], []);
    }
  };

  const handleChange = useCallback(
    (e: Pick<Event, "preventDefault" | "stopPropagation">) => {
      if (haveSubOptions) {
        setExpanded((expanded) => !expanded);
      } else {
        e.preventDefault();
        e.stopPropagation();
        handleOnChangeAll();
      }
    },
    [handleOnChangeAll, haveSubOptions],
  );

  return (
    <>
      <SeoAnchor
        href={`${seoHrefPrefix}${value}`}
        onKeyDown={handleKeyEvent({
          onEnter: handleChange,
        })}
      >
        <Option
          allChecked={allChecked}
          expanded={expanded}
          handleOnChangeAll={handleOnChangeAll}
          haveSubOptions={haveSubOptions}
          indeterminate={indeterminate}
          label={label}
          onClickLabel={onClickLabel}
          handleChange={handleChange}
          value={value}
          detailsLabel={detailsLabel}
          tabIndex={-1}
        />
      </SeoAnchor>
      {haveSubOptions && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <SubOptionsList disablePadding>
            {subOptions?.map(
              ({
                label: subLabel,
                value: subValue,
                detailsLabel: subDetailsLabel,
              }) => (
                <StyledListItemButton
                  key={subValue}
                  onClick={() => handleOnChange(subValue)}
                >
                  <StyledFormControlLabelSubOption
                    onClick={onClickLabel}
                    control={
                      <StyledCheckBox
                        checked={initialSelectedOptionValues.includes(subValue)}
                        onChange={() => handleOnChange(subValue)}
                      />
                    }
                    label={subLabel}
                  />
                  {subDetailsLabel && <SubLabel>{subDetailsLabel}</SubLabel>}
                </StyledListItemButton>
              ),
            )}
          </SubOptionsList>
        </Collapse>
      )}
    </>
  );
};

export default CheckboxGroup;

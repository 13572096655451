import { Theme } from "@mui/material";

export function handleKeyEvent({
  onEnter,
  onExit,
}: {
  onEnter?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onExit?: (e: React.KeyboardEvent<HTMLElement>) => void;
}) {
  return (event: React.KeyboardEvent<HTMLElement>) => {
    if (!event) {
      return;
    }
    if (onEnter && (event.key === "Enter" || event.key === " ")) {
      event.preventDefault();
      onEnter(event);
    }
    if (onExit && event.key === "Escape") {
      event.preventDefault();
      onExit(event);
    }
  };
}

export function defaultFocusOutline(theme: Theme) {
  return `3px solid ${theme.palette.primary.light}`;
}

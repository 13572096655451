import { styled, useTheme } from "@mui/material";
import {
  createContext,
  Dispatch,
  Fragment,
  FunctionComponent,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import Icon from "@mui/material/Icon";
import { useRouter } from "next/router";
import Link from "next/link";
import {
  DEFAULT_SIGN_IN_VALUE,
  DEFAULT_SIGN_UP_VALUE,
} from "../../../theme/defaultConstants";
import type { SettingsConfiguration } from "../../builder/Theme";
import { iframe } from "../../helpers/environment";
import { useConfiguration } from "../../dataAccess/api/configuration";
import { useNavigateSameTab } from "../../helpers/navigation";
import { useLogout } from "../../dataAccess/HTTPClient";
import { pageMapping } from "../../builder/mapping/page";
import { getNameInitials, splitFullName } from "../../helpers/formatter";
import { signIn, SignInData } from "../../dataAccess/api/token";
import { Alignment } from "../../types/theme";
import { signUp, SignUpData, useUser } from "../../dataAccess/api/user";
import EncasedImage from "../ui/EncasedImage";
import SkeletonLoader from "../ui/SkeletonLoader";
import { ThemeButton } from "../ui/ThemeComponents/ThemeButton";
import { PublicComponentProps } from "./PublicComponentProps";
import {
  MediaSetting,
  getJustificationByAlignment,
} from "../../builder/themeOptionsBuilder";
import { TypographyWithEllipsis } from "../ui/TypographyWithEllipsis";
import ButtonPopup from "../ui/ButtonPopup";
import LoginForm from "../ui/LoginForm";
import { ServerError } from "../../dataAccess/ServerError";
import { TransparentButton } from "../ui/TransparentButton";
import { Container } from "../ui/DefaultPageContainer";
import { BackButton } from "../ui/BackButton";
import Banner, { BannerConfiguration } from "../ui/Banner";
import OnlyMobile from "../ui/OnlyMobile";
import OnlyDesktop from "../ui/OnlyDesktop";
import SignUpForm from "../ui/SignUpForm";
import VerifyEmailFormPopUp from "../ui/VerifyEmailFormPopUp";
import CommunityAccessPopUp from "../ui/CommunityAccessPopUp";
import { StyledIconButton } from "./Search";
import { TextButton } from "../ui/TextButton";
import { EditorCtx } from "../../wysiwyg/EditorCtx";
import PageNavigation from "../ui/PageNavigation";
import type { NavigationComponentProps } from "../pages/PDP";
import type { LoginSettings } from "../pages/LogIn";
import type { SignupSettings } from "../pages/SignUp";

export const profileDataMobileAttribute = "data-mobile-profile-element";
export const profileDataDesktopAttribute = "data-desktop-profile-element";

interface Settings extends BannerConfiguration {
  desktopTopSpacing?: string;
  desktopBottomSpacing?: string;
}

type Props = PublicComponentProps<Settings> & NavigationComponentProps;

interface ProfileState {
  loginOpen: boolean;
  setLoginOpen: Dispatch<SetStateAction<boolean>>;
  signUpOpen: boolean;
  setSignUpOpen: Dispatch<SetStateAction<boolean>>;
}

const defaultProfileState: ProfileState = {
  loginOpen: false,
  setLoginOpen: () => {},
  signUpOpen: false,
  setSignUpOpen: () => {},
};

const MainContainer = styled(Banner)<
  { isUserLoggedIn?: boolean } & Pick<
    Settings,
    "desktopBottomSpacing" | "desktopTopSpacing"
  >
>(({ theme, desktopTopSpacing, desktopBottomSpacing }) => {
  const {
    [theme.breakpoints.up("lg")]: lg,
    [theme.breakpoints.down("md")]: md,
    ...medias
  } = theme.getMediaForSettings(() => ({
    paddingTop:
      desktopTopSpacing !== undefined
        ? `${desktopTopSpacing}`
        : MediaSetting.top,
    paddingBottom:
      desktopBottomSpacing !== undefined
        ? `${desktopBottomSpacing}`
        : MediaSetting.top,
  }));
  return {
    display: "flex",
    minHeight: "4rem",
    fontWeight: "bold",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: `${theme.defaultSpacing.md.page.edges}`,
      paddingRight: `${theme.defaultSpacing.md.page.edges}`,
      ...lg,
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.settings.layout?.mobile.top,
      paddingBottom: theme.settings.layout?.mobile.top, // this should be top because both paddings should be the same
      ...md,
    },
    ...medias,
  };
});

const Text = styled(TypographyWithEllipsis)<{ color?: string }>(
  ({ theme, color }) => ({
    color: color || theme.palette.text.bannerPaper,
    fontWeight: "bold",
  }),
);

const StyledSkeletonLoader = styled(SkeletonLoader)(() => ({
  margin: 0,
}));

const UserName = styled(Text)(() => ({
  marginLeft: "0.8rem",
}));

const StyledBackButton = styled(BackButton)(() => ({
  padding: 0,
  justifySelf: "left",
  minWidth: "fit-content",
}));

const StyledButtonPopup = styled(ButtonPopup)(() => ({
  marginLeft: "1rem",
}));

const ProfileLoggedInButton = styled(StyledButtonPopup)(() => ({
  "&:hover": {
    background: "transparent",
  },
}));

const SkeletonContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
}));

const FlexContiner = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

const StyledOnlyDesktop = styled(OnlyDesktop)(() => ({
  width: "100%",
}));

const CenteredButton = styled(TextButton)(() => ({
  alignSelf: "center",
}));

const StyledButton = styled(ThemeButton)(() => ({
  marginLeft: "1rem",
}));

const MobileContainer = styled(OnlyMobile)<{ isProfileFullWidth?: boolean }>(
  ({ isProfileFullWidth }) => ({
    display: "flex",
    width: "100%",
    paddingLeft: isProfileFullWidth ? "0rem" : "1.5rem",
    paddingRight: isProfileFullWidth ? "0rem" : "1.5rem",
    paddingTop: "0.75rem",
    paddingBottom: isProfileFullWidth ? "0rem" : "0.75rem",
  }),
);

const ProfileContainer = styled("div")<{
  alignment?: Alignment;
}>(({ alignment, theme }) => ({
  flex: 1,
  marginLeft: "1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: getJustificationByAlignment(alignment || "right"),
  [theme.breakpoints.up("md")]: {
    position: "relative",
    flexDirection: "row",
  },
}));

const StyledIcon = styled(Icon)<{
  textColor: Settings["layout"]["textColor"];
}>(({ textColor }) => ({
  color: `${textColor || "inherit"} !important`,
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  padding: `0 ${theme.defaultSpacing.md.page.edges} !important`,
  alignItems: "center",
}));

export function useProfileState(): ProfileState {
  const [loginOpen, setLoginOpen] = useState(false);
  const [signUpOpen, setSignUpOpen] = useState(false);
  return {
    loginOpen,
    setLoginOpen,
    signUpOpen,
    setSignUpOpen,
  };
}

const avatarSize = 40;
const avatarSizeLoading = avatarSize * 1.7;

export const ProfileContext = createContext({ ...defaultProfileState });
const Profile: FunctionComponent<Props> = ({
  backButtonIn,
  isBackButtonIn,
  navigation,
  settings: { layout },
  item,
}) => {
  const router = useRouter();
  const { configuration } = useConfiguration();
  const navigate = useNavigateSameTab();
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [signUpServerError, setSignUpServerError] = useState("");
  const { user: realUser, loadingUser } = useUser();
  const { mocks, isEditor, messages, setCurrentMessage } =
    useContext(EditorCtx);
  let user = realUser;
  if (mocks && "noUserMock" in mocks) {
    user = mocks?.noUserMock;
  }
  const { palette } = useTheme();
  const { loginOpen, setLoginOpen, signUpOpen, setSignUpOpen } =
    useContext(ProfileContext);
  const logout = useLogout(configuration?.isOpenMarketplace);
  const containerProps = {
    alignment: layout?.layoutAlignment,
  };
  const { contained } = layout || {};
  const onSubmit = async (data: SignInData) => {
    if (isEditor) {
      setCurrentMessage(messages.previewAlert);
      setLoginOpen(false);
      setSignUpOpen(false);
      return;
    }
    setLoading(true);
    try {
      await signIn(data).json();
      navigate(router);
    } catch (e) {
      if (e instanceof ServerError) {
        const errorResponse = e;
        setLoginError(errorResponse.getMessage());
      }
    }
    setLoading(false);
  };

  const onSignUpSubmit = async (data: SignUpData) => {
    if (isEditor) {
      setCurrentMessage(messages.previewAlert);
      return;
    }
    setLoading(true);
    try {
      const { firstName, lastName } = splitFullName(data.fullName);
      data.firstName = firstName;
      data.lastName = lastName;
      data.productSlug = item?.slug;
      await signUp(data).json();
      navigate(router);
    } catch (e) {
      if (e instanceof ServerError) {
        const errorResponse = e;
        setSignUpServerError(errorResponse.getMessage());
      }
    }
    setLoading(false);
  };

  const { loginSettings, signupSettings } = useMemo(() => {
    let loginSettings: SettingsConfiguration = {};
    let signupSettings: SettingsConfiguration = {};
    configuration?.publishedTemplate?.properties?.pages.forEach((page) => {
      switch (page?.id) {
        case "Login":
          loginSettings = page?.settings;
          break;
        case "SignUp":
          signupSettings = page?.settings;
          break;
        default:
          break;
      }
    });
    return {
      loginSettings: loginSettings as LoginSettings,
      signupSettings: signupSettings as SignupSettings,
    };
  }, [configuration]);

  const Wrapper = contained ? Container : Fragment;
  const signUpButtonTarget = iframe ? { target: "_parent" } : {};
  const showNavigation = !!(isBackButtonIn && backButtonIn === "Profile");

  const signUpLabel = signupSettings?.buttonLabel || DEFAULT_SIGN_UP_VALUE;
  const signInLabel = loginSettings?.buttonLabel || DEFAULT_SIGN_IN_VALUE;
  const signUpFormLabel = signupSettings?.label || DEFAULT_SIGN_UP_VALUE;
  const signInFormLabel = loginSettings?.label || DEFAULT_SIGN_IN_VALUE;

  return (
    <Wrapper>
      {loadingUser ? (
        <SkeletonContainer>
          <StyledSkeletonLoader
            variant="circular"
            height={avatarSizeLoading}
            width={avatarSizeLoading}
          />
          <StyledSkeletonLoader variant="text" height="1rem" width="10rem" />
        </SkeletonContainer>
      ) : (
        <MainContainer
          layout={layout}
          {...{ [profileDataDesktopAttribute]: true }}
          {...containerProps}
          isUserLoggedIn={user?.confirmed}
        >
          <>
            <MobileContainer isProfileFullWidth={contained}>
              {showNavigation && (
                <Link
                  href={pageMapping.PLP.path}
                  passHref
                  legacyBehavior
                  shallow
                >
                  <StyledBackButton textColor={layout?.textColor} />
                </Link>
              )}
              <ProfileContainer {...containerProps}>
                {!configuration?.ssoSetting.forceJwtAuth ? (
                  <ButtonPopup
                    {...{ [profileDataMobileAttribute]: true }}
                    ButtonComponent={StyledIconButton}
                    setPopupOpen={!user ? setSignUpOpen : undefined}
                    aria-label={signUpFormLabel}
                    popupContent={
                      <>
                        <FlexContiner>
                          {!user?.id ? (
                            <SignUpForm
                              loading={loading}
                              onSubmit={onSignUpSubmit}
                              serverError={signUpServerError}
                              closePopUp={() => setSignUpOpen(false)}
                              setOpenLoginInProfilePopUp={() => {
                                setSignUpOpen(false);
                                setLoginOpen(true);
                              }}
                              label={signUpFormLabel}
                              sublabel={signupSettings?.sublabel}
                              buttonLabel={signupSettings?.buttonLabel}
                            />
                          ) : (
                            <></>
                          )}
                          {user?.id && !user?.confirmed && (
                            <VerifyEmailFormPopUp email={user.email} />
                          )}
                          {user?.confirmed &&
                            !user?.organization?.allowedInMarketplace && (
                              <CommunityAccessPopUp />
                            )}
                          {user && (
                            <CenteredButton
                              textColor={palette.text.paper}
                              onClick={logout}
                            >
                              LOGOUT
                            </CenteredButton>
                          )}
                        </FlexContiner>
                      </>
                    }
                  >
                    <StyledIcon textColor={layout?.textColor}>
                      person
                    </StyledIcon>
                  </ButtonPopup>
                ) : (
                  <StyledIconButton
                    onClick={() =>
                      navigate(configuration.ssoSetting.signInRedirectUrl)
                    }
                  >
                    <StyledIcon textColor={layout?.textColor}>
                      person
                    </StyledIcon>
                  </StyledIconButton>
                )}
              </ProfileContainer>
            </MobileContainer>

            <StyledOnlyDesktop>
              <>
                <StyledContainer>
                  {showNavigation && (
                    <PageNavigation
                      navigation={{
                        type: navigation,
                        links: [{ label: item?.name || "" }],
                      }}
                      textColor={layout.textColor}
                    />
                  )}
                  {!user?.id ? (
                    <ProfileContainer {...containerProps}>
                      {!configuration?.ssoSetting.forceJwtAuth ? (
                        <StyledButtonPopup
                          popupOpen={signUpOpen}
                          setPopupOpen={setSignUpOpen}
                          customVariant="secondary"
                          ButtonComponent={ThemeButton}
                          aria-label={signUpFormLabel}
                          popupContent={
                            <SignUpForm
                              loading={loading}
                              onSubmit={onSignUpSubmit}
                              serverError={signUpServerError}
                              closePopUp={() => setSignUpOpen(false)}
                              setOpenLoginInProfilePopUp={() => {
                                setSignUpOpen(false);
                                setLoginOpen(true);
                              }}
                              label={signUpFormLabel}
                              sublabel={signupSettings?.sublabel}
                              buttonLabel={signupSettings?.buttonLabel}
                            />
                          }
                        >
                          {signUpLabel}
                        </StyledButtonPopup>
                      ) : (
                        <ThemeButton
                          href={configuration.ssoSetting.signUpRedirectUrl}
                          {...signUpButtonTarget}
                          customVariant="secondary"
                        >
                          {signUpLabel}
                        </ThemeButton>
                      )}
                      {!configuration?.ssoSetting.forceJwtAuth ? (
                        <StyledButtonPopup
                          popupOpen={loginOpen}
                          setPopupOpen={setLoginOpen}
                          ButtonComponent={ThemeButton}
                          aria-label={signInFormLabel}
                          popupContent={
                            <LoginForm
                              loading={loading}
                              onSubmit={onSubmit}
                              serverError={loginError}
                              closePopUp={() => setLoginOpen(false)}
                              setOpenSignUpInProfilePopup={() => {
                                setLoginOpen(false);
                                setSignUpOpen(true);
                              }}
                              label={signInFormLabel}
                              sublabel={loginSettings?.sublabel}
                              buttonLabel={loginSettings?.buttonLabel}
                            />
                          }
                        >
                          {signInLabel}
                        </StyledButtonPopup>
                      ) : (
                        <StyledButton
                          href={configuration.ssoSetting.signInRedirectUrl}
                          {...signUpButtonTarget}
                        >
                          {signInLabel}
                        </StyledButton>
                      )}
                    </ProfileContainer>
                  ) : (
                    <ProfileContainer {...containerProps}>
                      <ProfileLoggedInButton
                        popupOpen={loginOpen}
                        setPopupOpen={setLoginOpen}
                        disableRipple
                        ButtonComponent={TransparentButton}
                        popupContent={
                          <FlexContiner>
                            {!user.confirmed && (
                              <VerifyEmailFormPopUp email={user.email} />
                            )}
                            {user.confirmed &&
                              !user?.organization?.allowedInMarketplace && (
                                <CommunityAccessPopUp />
                              )}
                            <CenteredButton
                              textColor={palette.text.paper}
                              onClick={logout}
                            >
                              LOGOUT
                            </CenteredButton>
                          </FlexContiner>
                        }
                      >
                        <EncasedImage
                          alt={`${user.firstName} ${user.lastName}`}
                          height={avatarSize}
                          width={avatarSize}
                          src={user.pictureUrl}
                          variant="circle"
                          initials={getNameInitials(
                            user.firstName,
                            user.lastName,
                          )}
                        />
                        <UserName clamp={2} color={layout?.textColor}>
                          {user.firstName} {user.lastName}
                        </UserName>
                      </ProfileLoggedInButton>
                    </ProfileContainer>
                  )}
                </StyledContainer>
              </>
            </StyledOnlyDesktop>
          </>
        </MainContainer>
      )}
    </Wrapper>
  );
};

export default Profile;

import { Button, ButtonProps, styled } from "@mui/material";
import { ReactNode, Ref, forwardRef } from "react";
import { BodySizes } from "../../builder/useFontsLayout";

export const SeeMoreButton = styled(
  forwardRef(
    (
      props: ButtonProps & {
        children?: ReactNode;
        target?: string;
      },
      ref: Ref<HTMLButtonElement>,
    ) => <Button variant="text" ref={ref} {...props} />,
  ),
)(({ theme }) => ({
  fontSize:
    theme.typography[BodySizes[theme.settings.fonts.chips?.size || "medium"]]
      .fontSize,
}));

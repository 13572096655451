import { CSSObject } from "@emotion/react";
import { alpha, Chip, styled, Theme } from "@mui/material";
import { Size } from "../../../types/theme";
import { useCapitalization, useShadows } from "../../../builder/Theme";
import { BodySizes } from "../../../builder/useFontsLayout";

export function getThemeChipPadding(paddingSize: number, theme: Theme) {
  const verticalPadding = theme.spacing(paddingSize / 4);
  const horizontalPadding = theme.spacing(paddingSize / 1.5);
  return {
    padding: verticalPadding,
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
  };
}

export const ThemeChip = styled(Chip)(({ theme }) => {
  const { chips, fonts } = theme.settings;
  const textTransform = useCapitalization(chips?.capitalization);
  const boxShadow = useShadows(chips?.shadows);

  function getPadding(paddingSize: number) {
    const sidePadding = theme.spacing(paddingSize / 2.3);
    return {
      padding: theme.spacing(paddingSize),
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
    };
  }

  const sizes: Record<Size["size"], CSSObject> = {
    extraSmall: getPadding(0.875),
    small: getPadding(2),
    medium: getPadding(2.5),
    large: getPadding(3),
    extraLarge: getPadding(3.5),
  };

  return {
    ...sizes[chips.size],
    textTransform,
    boxShadow,
    borderStyle: "solid",
    borderWidth: chips.border.thickness,
    borderRadius: chips.border.radius * 10,
    borderColor: alpha(
      theme.palette.chipsBorderColor.main,
      chips.border.opacity,
    ),
    backgroundColor: theme.palette.chipsBackground.default,
    color: theme.palette.text.chipDefault,
    "&.MuiChip-outlined": {
      "&:hover": {
        borderColor: alpha(
          theme.palette.chipsHoverBorderColor.main,
          chips.border.opacity,
        ),
        color: theme.palette.text.chipHover,
        backgroundColor: theme.palette.chipsHoverBackground.default,
        "& .MuiAvatar-root": {
          backgroundColor: `${theme.palette.text.chipHover} !important`,
          color: theme.palette.chipsHoverBackground.default,
        },
      },
    },
    "& .MuiAvatar-root": {
      color: theme.palette.chipsBackground.default,
      fontSize: `calc(${
        theme.typography[BodySizes[fonts.chips?.size || "medium"]].fontSize
      } - 0.1rem)`,
    },
    "&.MuiChip-filled": {
      color: theme.palette.text.chipSelected,
      backgroundColor: theme.palette.chipsSelectedBackground.default,
      borderColor: alpha(
        theme.palette.chipsSelectedBorderColor.main,
        chips.border.opacity,
      ),
      "& .MuiAvatar-root": {
        backgroundColor: `${theme.palette.text.chipSelected} !important`,
        color: theme.palette.chipsSelectedBackground.default,
      },
    },
    "& .MuiChip-label": {
      fontSize:
        theme.typography[BodySizes[fonts.chips?.size || "medium"]].fontSize,
    },
  };
});

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  QueryParams,
  queryStringToArray,
  useAddQueryParams,
  useQueryParams,
} from "../../dataAccess/QueryParams";
import CheckboxTree from "./CheckboxTree";
import OptionList from "./OptionList";
import { Alignment, Position } from "../../types/theme";
import OnlyDesktop from "./OnlyDesktop";
import FilterSkeleton from "./SkeletonFilter";
import { removeDuplicates } from "../../helpers/array";

export type OptionValue = string;

export interface Option {
  value: OptionValue;
  label: string;
  title?: string;
  detailsLabel?: string;
  subOptions?: Omit<Option, "subOptions">[];
}

export interface MobileFilterProps {
  mobile?: boolean;
  defaultExpanded?: boolean;
}

interface ContainerProps {
  alignment?: Alignment;
}

interface Props extends MobileFilterProps {
  alignment: Alignment;
  variant: Position;
  queryParam: keyof QueryParams;
  title: string;
  options: { title?: string; values: Option[] }[];
  loading?: boolean;
}

const StyledAccordion = styled(Accordion)(() => ({
  background: "transparent",
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  paddingRight: 0,
  [theme.breakpoints.up("md")]: {
    minHeight: "3rem !important",
    paddingLeft: 0,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.text.default,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  marginRight: ".5rem",
  overflow: "auto",
  padding: "0",
  [theme.breakpoints.up("md")]: {
    maxHeight: "40rem",
  },
}));

const HorizontalContainer = styled("div")<ContainerProps>(({ alignment }) => ({
  textAlign: alignment === "right" ? "right" : "initial",
  margin: "0",
}));

const Title = styled(Typography)<{
  alignment?: Alignment;
}>(({ alignment }) => ({
  paddingRight: alignment === "right" ? ".7rem" : "initial",
  marginBottom: ".5rem",
  textAlign: "inherit",
  fontWeight: "bold",
}));

const TitleHorizontal = styled(Title)(() => ({
  marginBottom: "1rem",
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: "bold",
  paddingLeft: theme.defaultSpacing.md.page.edges,
  marginBottom: ".5rem",
}));

const CheckboxContainer = styled("div")<{ includesPadding: boolean }>(
  ({ includesPadding }) => ({
    paddingLeft: includesPadding ? "1rem" : 0,
  }),
);

export function useClearFilters() {
  const addQueryParams = useAddQueryParams();
  return useCallback(() => {
    addQueryParams([
      {
        paramKey: "partner_ids",
        paramValue: null,
      },
      {
        paramKey: "q",
        paramValue: null,
      },
      {
        paramKey: "page_number",
        paramValue: null,
      },
      {
        paramKey: "category_ids",
        paramValue: null,
      },
      {
        paramKey: "collection_ids",
        paramValue: null,
      },
    ]);
  }, []);
}

const Filter: FunctionComponent<Props> = ({
  variant,
  alignment,
  options,
  title,
  queryParam,
  mobile,
  defaultExpanded,
  loading,
}: Props) => {
  const setQueryParams = useAddQueryParams();
  const theme = useTheme();
  const queryParams = useQueryParams();
  const queryString = queryParams[queryParam];
  const queryValue = removeDuplicates(queryStringToArray(queryString));
  const currentQueryParamsRef = useRef<string[]>(queryValue);
  const [currentQueryParams, setCurrentQueryParams] =
    useState<string[]>(queryValue);

  useEffect(() => {
    currentQueryParamsRef.current = currentQueryParams;
  }, [currentQueryParams]);

  function setFilterQueryParams(value: string | null) {
    setQueryParams([
      {
        paramKey: "page_number",
        paramValue: null,
      },
      {
        paramKey: queryParam,
        paramValue: value,
      },
    ]);
  }

  useEffect(
    () => () => {
      if (
        queryValue.sort().toString() !==
        currentQueryParamsRef.current.sort().toString()
      ) {
        setFilterQueryParams(
          currentQueryParamsRef.current.length
            ? currentQueryParamsRef.current.toString()
            : null,
        );
      }
    },
    [],
  );

  const nonVerticalOptions = useMemo(() => {
    if (variant !== "vertical") {
      return options.map(({ values }) => values).flat();
    }
  }, [variant, options]);

  const onChange = useCallback(
    (checked: OptionValue[]) => {
      if (mobile) {
        setCurrentQueryParams(removeDuplicates(checked));
      } else {
        setFilterQueryParams(checked.length ? checked.toString() : null);
      }
    },
    [queryParam, setQueryParams, mobile],
  );

  const Wrapper = mobile ? Fragment : OnlyDesktop;
  const FilterArrowIcon = theme.icons.arrowDown;
  if (loading) {
    return (
      <Wrapper>
        <FilterSkeleton variant={variant} mobile={mobile} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {variant === "vertical" ? (
        <div>
          <StyledAccordion elevation={0} defaultExpanded={defaultExpanded}>
            <StyledAccordionSummary expandIcon={<FilterArrowIcon />}>
              <Title variant="h5">{title}</Title>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              {options.map(({ values, title }) => (
                <>
                  {!!title && <Subtitle variant="h6">{title}</Subtitle>}
                  <CheckboxContainer includesPadding={!!title}>
                    <CheckboxTree
                      onChange={onChange}
                      options={values}
                      initialSelectedOptionValues={
                        mobile ? currentQueryParams : queryValue
                      }
                      defaultCollapsed={!!mobile || !defaultExpanded}
                      seoHrefPrefix={`/?${queryParam}=`}
                    />
                  </CheckboxContainer>
                </>
              ))}
            </StyledAccordionDetails>
          </StyledAccordion>
        </div>
      ) : (
        <HorizontalContainer alignment={alignment}>
          <>
            <TitleHorizontal variant="h5" alignment={alignment}>
              {title}
            </TitleHorizontal>
            <OptionList
              onChange={onChange}
              options={nonVerticalOptions || []}
              initialSelectedOptionValues={queryValue}
              seoHrefPrefix={`/?${queryParam}=`}
              title={title}
            />
          </>
        </HorizontalContainer>
      )}
    </Wrapper>
  );
};

export default Filter;

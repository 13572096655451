import { FunctionComponent, ReactNode, useMemo, useState } from "react";
import { Badge, Button, styled } from "@mui/material";
import Icon from "@mui/material/Icon";
import { getJustificationByAlignment } from "../../builder/themeOptionsBuilder";
import SortButtonDropdown from "../ui/SortButtonDropdown";
import SearchBar from "../ui/SearchBar";
import { Alignment, InputSettings, Position } from "../../types/theme";
import { useClearFilters } from "../ui/Filter";
import { PublicComponentProps } from "./PublicComponentProps";
import { PartnerResponse } from "../../dataAccess/api/partners";
import { CategoryResponse } from "../../dataAccess/api/categories";
import { CollectionHeader } from "../../dataAccess/api/collections";
import CategoriesFilter from "./CategoriesFilter";
import CollectionsFilter from "./CollectionsFilter";
import PartnersFilter from "./PartnersFilter";
import ButtonPopup from "../ui/ButtonPopup";
import { GridSettings, GridSubComponents } from "./Grid";
import OnlyMobile from "../ui/OnlyMobile";
import OnlyDesktop from "../ui/OnlyDesktop";
import { useQueryParams } from "../../dataAccess/QueryParams";
import { DropdownItem } from "../ui/DropdownList";
import { defaultFocusOutline } from "../../helpers/accessibility";

export interface Settings {
  justification: Exclude<Alignment, "center">;
  alignment: Exclude<Alignment, "center">;
  position: Position;
  multipleSearch: boolean;
  hideSort: boolean;
  sortOptions: DropdownItem[];
  input: InputSettings;
}

interface Props
  extends PublicComponentProps<
    Settings,
    Record<string, never>,
    GridSubComponents,
    GridSettings
  > {
  partnerList?: PartnerResponse;
  categories?: CategoryResponse;
  collections?: CollectionHeader[];
}

interface ContainerProps {
  justification: Settings["justification"];
  position?: Settings["position"];
}

const SearchContainer = styled("div")<ContainerProps>(
  ({ theme, justification }) => ({
    width: "100%",
    display: "flex",
    gap: theme.spacing(1),
    flexDirection: justification === "left" ? "row" : "row-reverse",
    alignItems: "center",
  }),
);

const StyledButton = styled(Button)(({ theme }) => ({
  height: "2.5rem",
  maxWidth: "3.5rem",
  minWidth: "3.5rem",
  padding: "0 !important",
  border: "none !important",
  "&:hover": {
    backgroundColor: "transparent",
  },
  color: theme.palette.inputs.default,
  [theme.breakpoints.up("md")]: {
    color: theme.palette.inputs.paper,
    fontSize: theme.typography.body2.fontSize,
    width: "auto",
    textTransform: "none",
  },
}));

const FilterActionsButtonContainer = styled("div")(() => ({
  textAlign: "right",
}));

const StyledFilterIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.text.default,
}));

const SearchBarContainerDesktop = styled(OnlyDesktop)(() => ({
  flex: "1",
}));

export const StyledIconButton = styled(Button)(() => ({
  padding: 0,
  minWidth: "fit-content",
}));

const StyledSearchIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.text.default,
}));

const StyledSearchIconButton = styled(StyledIconButton)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    marginLeft: "0.8rem",
  },
}));

const FilterActionsButton = styled(Button)(() => ({
  fontWeight: "bold",
}));

const MobileSearchBarContainer = styled("div")(() => ({
  width: "calc(100vw - 2rem)",
  maxWidth: "100%",
  padding: "0 1rem",
}));

const SearchBarContainerMobile = styled(OnlyMobile)<
  Pick<Settings, "alignment">
>(({ theme, alignment }) => {
  const buttonSize = 30;
  return {
    display: "flex",
    justifyContent: getJustificationByAlignment(alignment),
    "& .MuiButton-root": {
      "&:focus": {
        outline: defaultFocusOutline(theme),
      },
      width: buttonSize,
      height: buttonSize,
      minWidth: buttonSize,
      background: "transparent",
      "& .MuiTouchRipple-root": {
        display: "none",
      },
    },
  };
});

const SearchBarContainerMobileContent = styled(OnlyMobile)<
  Pick<Settings, "justification">
>(({ theme, justification }) => ({
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(1),
  flexDirection: justification === "right" ? "row" : "row-reverse",
}));

function getSearchFilters(filters: (string | undefined)[]) {
  return filters
    .map((filterStr) => filterStr?.split(","))
    .flat()
    .filter((exist) => exist);
}

function useSearchFiltersFromUrl() {
  const { q, category_ids, collection_ids, partner_ids } = useQueryParams();
  return useMemo(
    () => ({
      filterList: getSearchFilters([category_ids, collection_ids, partner_ids]),
      searchList: getSearchFilters([q]),
    }),
    [q, category_ids, collection_ids, partner_ids],
  );
}

const Search: FunctionComponent<Props> = ({
  settings: {
    justification,
    multipleSearch,
    sortOptions,
    position,
    alignment,
    hideSort,
    input,
  },
  siblingsComponents,
  categories,
  collections,
  partnerList,
}: Props) => {
  const { filterList, searchList } = useSearchFiltersFromUrl();
  const clearFilters = useClearFilters();
  const [openInMobile, setOpenInMobile] = useState(false);
  const mobileVerticalFilters: Record<string, ReactNode> = useMemo(() => {
    const position = "vertical";
    const keys = (siblingsComponents && Object.keys(siblingsComponents)) || [];
    return {
      CategoriesFilter: siblingsComponents?.CategoriesFilter && (
        <CategoriesFilter
          subComponents={{}}
          mobile
          categories={categories}
          settings={{
            ...siblingsComponents?.CategoriesFilter.settings,
            position,
          }}
          defaultExpanded={keys.indexOf("CategoriesFilter") > 0}
        />
      ),
      CollectionsFilter: siblingsComponents?.CollectionsFilter && (
        <CollectionsFilter
          subComponents={{}}
          mobile
          collections={collections}
          settings={{
            ...siblingsComponents?.CollectionsFilter.settings,
            position,
          }}
          defaultExpanded={keys.indexOf("CollectionsFilter") > 0}
        />
      ),
      PartnersFilter: siblingsComponents?.PartnersFilter && (
        <PartnersFilter
          subComponents={{}}
          mobile
          partnerList={partnerList}
          settings={{
            ...siblingsComponents?.PartnersFilter.settings,
            position,
          }}
          defaultExpanded={keys.indexOf("PartnersFilter") > 0}
        />
      ),
    };
  }, [categories, collections, partnerList, siblingsComponents]);

  const mobileFilters = useMemo(
    () =>
      Object.keys(siblingsComponents || {})
        .filter((key) => key !== "Search")
        .map((key) => mobileVerticalFilters[key]),
    [siblingsComponents, mobileVerticalFilters],
  );
  // TODO: Remove this after confirming the loading state is not needed for the search
  // if (loadingPLP) {
  //   return (
  //     <SearchContainerLoader justification={justification}>
  //       <SkeletonLoader variant="rectangular" height="3rem" width="90%" />
  //       <SkeletonLoader variant="rectangular" height="3rem" width="10%" />
  //     </SearchContainerLoader>
  //   );
  // }

  return (
    <>
      <SearchContainer justification={justification} position={position}>
        <SearchBarContainerDesktop>
          <SearchBar
            multipleSearch={multipleSearch}
            alignment={alignment}
            input={input}
          />
        </SearchBarContainerDesktop>
        {!hideSort && (
          <OnlyDesktop>
            <div>
              <SortButtonDropdown options={sortOptions} />
            </div>
          </OnlyDesktop>
        )}
      </SearchContainer>
      <SearchBarContainerMobile alignment={alignment}>
        <SearchBarContainerMobileContent justification={justification}>
          {/* TODO: Replace this for DropdownList component */}
          {!hideSort && (
            <SortButtonDropdown
              options={sortOptions}
              aria-label="Sort" // TODO: handle translation
            />
          )}
          {mobileFilters.length > 0 && (
            <ButtonPopup
              ButtonComponent={StyledButton}
              popupOpen={openInMobile}
              aria-expanded={openInMobile}
              setPopupOpen={setOpenInMobile}
              aria-label="Filters" // TODO: handle translation
              popupContent={
                <>
                  <FilterActionsButtonContainer>
                    <FilterActionsButton onClick={() => setOpenInMobile(false)}>
                      APPLY
                    </FilterActionsButton>
                    <FilterActionsButton
                      color="error"
                      onClick={() => {
                        setOpenInMobile(false);
                        clearFilters();
                      }}
                    >
                      RESET
                    </FilterActionsButton>
                  </FilterActionsButtonContainer>
                  {mobileFilters}
                </>
              }
            >
              <Badge badgeContent={filterList.length} color="primary">
                <StyledFilterIcon>filter_alt</StyledFilterIcon>
              </Badge>
            </ButtonPopup>
          )}
          <ButtonPopup
            ButtonComponent={StyledSearchIconButton}
            popupContent={
              <MobileSearchBarContainer aria-live="polite">
                <SearchBar
                  multipleSearch={multipleSearch}
                  alignment={alignment}
                  input={input}
                  autoFocus
                />
              </MobileSearchBarContainer>
            }
            aria-label="Search" // TODO: handle translation
          >
            <Badge
              badgeContent={multipleSearch ? searchList.length : 0}
              color="primary"
            >
              <StyledSearchIcon>search</StyledSearchIcon>
            </Badge>
          </ButtonPopup>
        </SearchBarContainerMobileContent>
      </SearchBarContainerMobile>
    </>
  );
};

export default Search;
